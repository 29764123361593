export const learnMoreCarbonLink = [
  'trackEvent',
  'Accueil',
  'Click "En savoir plus LP carbone"',
]

export const learnMoreWaterLink = [
  'trackEvent',
  'Accueil',
  'Click "En savoir plus LP eau"',
]

export const createGroupLink = ['trackEvent', 'Accueil', 'Click "Groupes"']

export const createOrganisationLink = [
  'trackEvent',
  'Accueil',
  'Click "Organisations"',
]
